import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cornerstone from 'cornerstone-core';

import { DefragmentationForm } from '../forms/DefragmentationForm';
import TOOL_NAMES from '../../../tools/constants/toolNames';
import * as commands from '../../../tools/commands';

export function DefragmentationCompute({
  createPainters,
  popActivePainters,
  ROIContours,
  onCommand,
  onFinished,
}) {
  const [activePainters, setActivePainters] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const isSubmitting = useRef(false);
  const isDeleting = useRef(false);

  const onFormCreate = () => {
    onCommand('setToolActive', { toolName: TOOL_NAMES.DEFRAGMENTATION_TOOL });
    const painters = createPainters('defragmentation');
    setActivePainters(painters);
  };

  const onFormSelectChange = (targetROIContour, minArea) => {
    if (!targetROIContour) return;
    commands.refreshViewport();
    for (let i = 0; i < activePainters.length; i++) {
      const { ROINumber: targetROINumber } = targetROIContour;
      const pixelSpacing = cornerstone.metaData.get(
        'PixelSpacing',
        activePainters[i].imageId
      );
      activePainters[i].update({ targetROINumber, minArea, pixelSpacing });
    }
  };

  const onFormSubmit = ({ targetROIContour }) => {
    try {
      if (!targetROIContour) {
        throw new Error('Please select a target ROI Contour');
      }
      for (let i = 0; i < activePainters.length; i++) {
        activePainters[i].commit();
      }
      commands.refreshViewport();
      isSubmitting.current = true;
      onFinished();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onFormDelete = () => {
    isDeleting.current = true;
    popActivePainters();
    onFinished();
  };

  useEffect(() => {
    onFormCreate();
    return () => {
      if (!isSubmitting.current && !isDeleting.current) popActivePainters();
    };
  }, []);

  return (
    <DefragmentationForm
      ROIContours={ROIContours}
      onSelectChange={onFormSelectChange}
      onSubmit={onFormSubmit}
      onDelete={onFormDelete}
      errorMessage={errorMessage}
    />
  );
}
DefragmentationCompute.propTypes = {
  createPainters: PropTypes.func,
  popActivePainters: PropTypes.func,
  ROIContours: PropTypes.arrayOf(
    PropTypes.shape({ ROINumber: PropTypes.number })
  ),
  onCommand: PropTypes.func,
  onFinished: PropTypes.func,
};
