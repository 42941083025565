import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Select, Input, P, Alert } from '@platform/ui';

export const DefragmentationForm = ({
  ROIContours,
  onSelectChange,
  onSubmit,
  onDelete,
  errorMessage = null,
}) => {
  const [targetROIContour, setTargetROIContour] = useState('');
  const [minArea, setMinArea] = useState(0);

  return (
    <div
      style={{
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      <div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Target ROI Contour:</P>
          <Select
            onChange={evt => {
              const targetROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setTargetROIContour(targetROIContour);
              onSelectChange(targetROIContour, Number(minArea) * 100);
            }}
          >
            <option value={-1}>------</option>
            {ROIContours.map(c => {
              return (
                <option key={c.ROINumber} value={c.ROINumber}>
                  {c.ROIName}
                </option>
              );
            })}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>
            Minimum Area Value (cm<sup>2</sup>):
          </P>
          <Input
            value={minArea}
            onChange={evt => {
              setMinArea(evt.target.value);
              onSelectChange(targetROIContour, Number(evt.target.value) * 100);
            }}
          />
        </div>
        <div style={{ margin: '10px 0 0' }}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </div>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={() => onDelete()}
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => onSubmit({ targetROIContour })}
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
DefragmentationForm.propTypes = {
  ROIContours: PropTypes.arrayOf(
    PropTypes.shape({ ROINumber: PropTypes.number })
  ),
  onSelectChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};
