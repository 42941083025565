export const defaultState = {
  cornerstone: {
    isScrollSync: false,
    isPanZoomSync: false,
    isWwwcSync: false,
  },
  dicomRT: {
    seriesStructureMap: null,
    structureSets: [],
    selectedStructureSetUID: '',
    isReadOnly: false,
    isComputing: false,
    isLinking: false,
    images: {},
  },
  tracking: {
    scale: 0,
    windowWidth: 0,
    windowLevel: 0,
    notes: {},
  },
};

const extensions = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_EXTENSION_DATA': {
      const extensionName = action.extension;
      const currentData = state[extensionName] || {};
      const incomingData = action.data;
      const extension = {
        [extensionName]: { ...currentData, ...incomingData },
      };
      return { ...state, ...extension };
    }
    case 'SET_SCROLL_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isScrollSync: isToggled },
      };
    }
    case 'SET_PAN_ZOOM_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isPanZoomSync: isToggled },
      };
    }
    case 'SET_WWWC_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isWwwcSync: isToggled },
      };
    }
    case 'SET_DICOMRT_IMAGE_DATA': {
      const imageId = action.imageId;
      const imageData = action.data;
      return {
        ...state,
        dicomRT: {
          ...state.dicomRT,
          images: { ...state.dicomRT.images, [imageId]: imageData },
        },
      };
    }
    case 'SET_SERIES_STRUCTURE_MAP': {
      const { seriesStructureMap } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, seriesStructureMap } };
    }
    case 'SET_STRUCTURE_SETS': {
      const { structureSets } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, structureSets } };
    }
    case 'SET_SELECTED_STRUCTURE_SET_UID': {
      const { selectedStructureSetUID } = action.payload;
      return {
        ...state,
        dicomRT: { ...state.dicomRT, selectedStructureSetUID },
      };
    }
    case 'SET_IS_READ_ONLY': {
      const { isReadOnly } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, isReadOnly } };
    }
    case 'SET_IS_COMPUTING': {
      const { isComputing } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, isComputing } };
    }
    case 'SET_IS_LINKING': {
      const { isLinking } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, isLinking } };
    }
    case 'SET_TRACKING_SCALE': {
      const { scale } = action.payload;
      return {
        ...state,
        tracking: { ...state.tracking, scale },
      };
    }
    case 'SET_TRACKING_WINDOW_LEVEL': {
      const { windowLevel } = action.payload;
      return {
        ...state,
        tracking: { ...state.tracking, windowLevel },
      };
    }
    case 'SET_TRACKING_WINDOW_WIDTH': {
      const { windowWidth } = action.payload;
      return {
        ...state,
        tracking: { ...state.tracking, windowWidth },
      };
    }
    case 'SET_TRACKING_NOTES': {
      const { notes } = action.payload;
      return {
        ...state,
        tracking: { ...state.tracking, notes },
      };
    }
    default: {
      return state;
    }
  }
};

export default extensions;
