import cornerstone from 'cornerstone-core';
import { importInternal } from 'cornerstone-tools';
import _ from 'lodash';
import { math } from '../../modules/dicom-measurement/src';

const draw = importInternal('drawing/draw');
const { polygonArea } = math;

export function defragmentationPainter(
  toolState,
  structureSetSeriesInstanceUid
) {
  let state = _.cloneDeep(toolState);
  let targetROINumber = '';
  let minArea = '';
  let pixelSpacing = '';
  let defragmentationData = [];

  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      if (!defragmentationData) {
        return;
      }
      state.data = [
        ...state.data.filter(d => d.ROINumber !== targetROINumber),
        ...defragmentationData,
      ];

      // clear preview line
      targetROINumber = '';
      minArea = '';
      pixelSpacing = '';
    },

    update: function(evt) {
      defragmentationData = null;
      targetROINumber = evt.targetROINumber;
      minArea = evt.minArea;
      pixelSpacing = evt.pixelSpacing;
      defragmentationData = getDefragmentationData({
        state,
        structureSetSeriesInstanceUid,
        targetROINumber,
        minArea,
        pixelSpacing,
      });
    },

    cursor: function(evt, context) {
      if (!targetROINumber && targetROINumber !== 0) return;
      const element = evt.detail.element;
      const pointsData = defragmentationData.map(data =>
        data.handles.points.map(point =>
          cornerstone.pixelToCanvas(element, point)
        )
      );

      pointsData.forEach(points => {
        draw(context, context => {
          context.strokeStyle = 'rgba(217, 83, 79, 1)';
          context.fillStyle = 'rgba(217, 83, 79, 0.2)';
          context.lineWidth = 3;
          context.beginPath();
          context.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            context.lineTo(points[i].x, points[i].y);
          }
          context.closePath();
          context.stroke();
          context.fill();
        });
      });
      return true;
    },
  };
}

export function getDefragmentationData({
  state,
  structureSetSeriesInstanceUid,
  targetROINumber,
  minArea,
  pixelSpacing,
}) {
  const defragmentedPolygons = [];
  const polygons = state.data
    .filter(d => d.ROINumber === targetROINumber)
    .map(d => d.handles.points);

  polygons.forEach(vertices => {
    const area = polygonArea(vertices) * pixelSpacing[0] * pixelSpacing[1];
    if (area > minArea) defragmentedPolygons.push(vertices);
  });

  const defragmentationData = defragmentedPolygons.map(vertices => ({
    ROINumber: targetROINumber,
    structureSetSeriesInstanceUid: structureSetSeriesInstanceUid,
    handles: { points: vertices },
  }));

  return defragmentationData;
}
