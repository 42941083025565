const TOOL_NAMES = {
  RTSTRUCT_DISPLAY_TOOL: 'RTStructDisplayTool',
  EDGE_TOOL: 'EdgeTool',
  POLYGON_TOOL: 'PolygonTool',
  DELETE_TOOL: 'DeleteTool',
  REPULSER_TOOL: 'RepulserTool',
  SPHERE_THRESHOLD_TOOL: 'SphereThresholdTool',
  REGION_GROWING_TOOL: 'RegionGrowingTool',
  BOOLEAN_TOOL: 'BooleanTool',
  MERGE_TOOL: 'MergeTool',
  INTERPOLATION_TOOL: 'InterpolationTool',
  DEFRAGMENTATION_TOOL: 'DefragmentationTool',
  EXPANSION_TOOL: 'ExpansionTool',
};

export default TOOL_NAMES;
