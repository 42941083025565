import { MODULE_TYPES, utils } from '@platform/core';
import loadRTStruct from './loadRTStruct';

const SOP_CLASS_UIDS = { DICOM_RT_STRUCT: '1.2.840.10008.5.1.4.1.1.481.3' };
const sopClassUIDs = Object.values(SOP_CLASS_UIDS);

const DicomRTStructSopClassHandler = {
  id: 'DicomRTStructSopClassHandler',
  type: MODULE_TYPES.SOP_CLASS_HANDLER,
  sopClassUIDs,
  getDisplaySetFromSeries: function(
    series,
    study,
    dicomWebClient,
    authorizationHeaders
  ) {
    const instance = series.getFirstInstance();
    const metadata = instance.getData().metadata;
    const {
      SeriesDate,
      SeriesTime,
      SeriesNumber,
      SeriesDescription,
      FrameOfReferenceUID,
      SOPInstanceUID,
      SeriesInstanceUID,
      StudyInstanceUID,
    } = metadata;

    // TODO -> GET REFERENCED FRAME OF REFERENCE SEQUENCE.

    const rtStructDisplaySet = {
      Modality: 'RTSTRUCT',
      displaySetInstanceUID: utils.guid(),
      wadoRoot: study.getData().wadoRoot,
      wadoUri: instance.getData().wadouri,
      SOPInstanceUID,
      SeriesInstanceUID,
      StudyInstanceUID,
      FrameOfReferenceUID,
      authorizationHeaders,
      isDerived: true,
      referencedDisplaySetUID: null, // Assigned when loaded.
      labelmapIndex: null, // Assigned when loaded.
      isLoaded: false,
      SeriesDate,
      SeriesTime,
      SeriesNumber,
      SeriesDescription,
      metadata,
    };

    if (!metadata.ReferencedSeriesSequence) {
      const ReferencedFrameOfReferenceSequence =
        metadata.ReferencedFrameOfReferenceSequence;
      if (ReferencedFrameOfReferenceSequence) {
        // TODO -> @dannyrb Do we augment metadata or add a (potentially large? fallback list in filterDerivedDisplaySets )
        metadata.ReferencedSeriesSequence = _deriveReferencedSeriesSequenceFromFrameOfReferenceSequence(
          ReferencedFrameOfReferenceSequence
        );
      }
    }

    rtStructDisplaySet.load = async function(
      studies,
      rtStructDisplaySet,
      config
    ) {
      return await loadRTStruct(studies, rtStructDisplaySet, config).catch(
        error => {
          rtStructDisplaySet.isLoaded = false;
          rtStructDisplaySet.loadError = true;
          throw new Error(error);
        }
      );
    };

    return rtStructDisplaySet;
  },
};

function _deriveReferencedSeriesSequenceFromFrameOfReferenceSequence(
  ReferencedFrameOfReferenceSequence
) {
  const ReferencedSeriesSequence = [];

  _getSequenceAsArray(ReferencedFrameOfReferenceSequence).forEach(
    referencedFrameOfReference => {
      const { RTReferencedStudySequence } = referencedFrameOfReference;

      _getSequenceAsArray(RTReferencedStudySequence).forEach(
        rtReferencedStudy => {
          const { RTReferencedSeriesSequence } = rtReferencedStudy;

          _getSequenceAsArray(RTReferencedSeriesSequence).forEach(
            rtReferencedSeries => {
              const ReferencedInstanceSequence = [];
              const {
                ContourImageSequence,
                SeriesInstanceUID,
              } = rtReferencedSeries;

              _getSequenceAsArray(ContourImageSequence).forEach(
                contourImage => {
                  ReferencedInstanceSequence.push({
                    ReferencedSOPInstanceUID:
                      contourImage.ReferencedSOPInstanceUID,
                    ReferencedSOPClassUID: contourImage.ReferencedSOPClassUID,
                  });
                }
              );

              const referencedSeries = {
                SeriesInstanceUID,
                ReferencedInstanceSequence,
              };

              ReferencedSeriesSequence.push(referencedSeries);
            }
          );
        }
      );
    }
  );

  return ReferencedSeriesSequence;
}

function _getSequenceAsArray(sequence) {
  return Array.isArray(sequence) ? sequence : [sequence];
}

export default DicomRTStructSopClassHandler;
