import 'regenerator-runtime/runtime';

import App from './App.js';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './main.css';

/** config */
import config from './config/index.js';

/** store */
import store from './store/index.js';

/** Contexts */
import { AppContext } from './context/AppContext.js';
import { APIContext } from './context/APIContext.js';
import { PACSContext } from './context/PACSContext.js';
export { store, AppContext, APIContext, PACSContext };

window.addEventListener('load', function() {
  main();
});

function main() {
  /** create app and render */
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App config={config} />);
}
