/** platform.vysioneer.com */

import getHotkeys from './getHotkeys';
import { getPrefetcher, getDICOMwebUrl } from './getUrlSearchParams';

export default function config() {
  const baseMap = {
    default: 'https://arched-jigsaw-248702.uc.r.appspot.com/healthcare',
    'us-east1': 'https://arched-jigsaw-248702.uc.r.appspot.com/healthcare',
    'us-central1': 'https://arched-jigsaw-248702.uc.r.appspot.com/healthcare',
    'asia-east1':
      'https://vysioneer-production-platform.de.r.appspot.com/healthcare',
    'asia-northeast1':
      'https://vysioneer-production-platform.de.r.appspot.com/healthcare',
  };
  const urlRoot = getDICOMwebUrl({ baseMap: baseMap });
  return {
    routerBasename: '/',
    homepageUrl: 'https://platform.vysioneer.com',
    redirectUrl: 'https://platform.vysioneer.com',
    showPatientList: false,
    readOnly: false,
    enableOnPremiseAPI: false,
    enableSubmitRtstructButton: true,
    enableDownloadRtstructButton: false,
    enableRTExtension: true,
    enableTrackingExtension: false,
    enableLongitudinalExtension: true,
    enableLocal: true,
    firebaseConfig: {
      apiKey: 'AIzaSyB1iMieoQK6WXEk1r-oLYvCIrXe5rxjftg',
      authDomain: 'app.vysioneer.com',
      projectId: 'arched-jigsaw-248702',
      storageBucket: 'arched-jigsaw-248702.appspot.com',
      messagingSenderId: '1031692310475',
      appId: '1:1031692310475:web:a4b56388bc8c1d2d03084e',
      measurementId: 'G-YG2R0WSMVS',
      autoLogout: true,
      enableSubmitLogs: false,
      enableUpdateEndpoints: false,
    },
    dicomWebServer: 'healthcareApi',
    dicomWebProxy: {
      projectId: 'arched-jigsaw-248702',
      endpoint: baseMap,
    },
    service: {
      endpoint: {
        default: 'https://arched-jigsaw-248702.uc.r.appspot.com/service',
      },
    },
    servers: {
      dicomWeb: [
        {
          name: 'Google Healthcare API',
          imageRendering: 'wadors',
          thumbnailRendering: 'wadors',
          type: 'dicomWeb',
          active: true,
          wadoUriRoot: urlRoot,
          qidoRoot: urlRoot,
          wadoRoot: urlRoot,
          supportsFuzzyMatching: false,
          qidoSupportsIncludeField: false,
        },
      ],
    },
    studyPrefetcher: getPrefetcher({
      enabled: true,
      displaySetCount: 3,
      maxNumPrefetchRequests: 30,
    }),
    maxConcurrentMetadataRequests: 5,
    maxCacheSizeInBytes: 2 * 1024 * 1024 * 1024,
    hotkeys: getHotkeys(),
  };
}
