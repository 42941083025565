import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';

import { Select, P } from '@platform/ui';
import { MergeCompute } from './MergeCompute';
import { BooleanCompute } from './BooleanCompute';
import { DefragmentationCompute } from './DefragmentationCompute';
import { ExpansionCompute } from './ExpansionCompute';
import * as commands from '../../../tools/commands';

export default function RTCompute({
  axialImageSets,
  SeriesInstanceUID: uid,
  ROIContours,
  addNewROIContourWithName,
  onROISelect,
  onROIDelete,
  onCommand,
  onFinished,
}) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const [computingMethod, setComputingMethod] = useState('merge');

  const createPainters = mode => {
    const painters = [];
    for (const imageSet of axialImageSets) {
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        const p = editModule.getters.peekPainter(imageId);
        if (!p || p.structureSetSeriesInstanceUid !== uid) continue;
        const painter = editModule.setters.createPainter(mode, imageId, uid);
        painters.push(painter);
      }
    }
    return painters;
  };

  const popActivePainters = () => {
    for (const imageSet of axialImageSets) {
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        editModule.setters.popPainter(imageId);
      }
    }
    commands.refreshViewport();
  };

  const ComputingModule = {
    'boolean-operation': (
      <BooleanCompute
        createPainters={createPainters}
        popActivePainters={popActivePainters}
        ROIContours={ROIContours}
        addNewROIContourWithName={addNewROIContourWithName}
        onCommand={onCommand}
        onFinished={onFinished}
      />
    ),
    merge: (
      <MergeCompute
        createPainters={createPainters}
        popActivePainters={popActivePainters}
        ROIContours={ROIContours}
        onROISelect={onROISelect}
        onROIDelete={onROIDelete}
        onCommand={onCommand}
        onFinished={onFinished}
      />
    ),
    expansion: (
      <ExpansionCompute
        createPainters={createPainters}
        popActivePainters={popActivePainters}
        ROIContours={ROIContours}
        addNewROIContourWithName={addNewROIContourWithName}
        onCommand={onCommand}
        onFinished={onFinished}
      />
    ),
    defragmentation: (
      <DefragmentationCompute
        createPainters={createPainters}
        popActivePainters={popActivePainters}
        ROIContours={ROIContours}
        onCommand={onCommand}
        onFinished={onFinished}
      />
    ),
  };

  return (
    <div
      style={{
        margin: '10px 0 0',
        padding: '0 0 15px',
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <div style={{ margin: '10px 0 0' }}>
        <P style={{ color: 'var(--default-color)' }}>Class:</P>
        <Select
          value={computingMethod}
          onChange={evt => setComputingMethod(evt.target.value)}
          size="small"
          variant="outlined"
          fullWidth
        >
          <option value={'merge'}>Merge</option>
          <option value={'boolean-operation'}>Boolean Operators</option>
          {/* <option value={'defragmentation'}>Defragmentation</option> */}
          <option value={'expansion'}>Margin for Structure</option>
        </Select>
      </div>
      {ComputingModule[computingMethod] || null}
    </div>
  );
}
RTCompute.propTypes = {
  axialImageSets: PropTypes.array,
  SeriesInstanceUID: PropTypes.string,
  ROIContours: PropTypes.array,
  addNewROIContourWithName: PropTypes.func,
  onROISelect: PropTypes.func,
  onROIDelete: PropTypes.func,
  onCommand: PropTypes.func,
  onFinished: PropTypes.func,
};
