import TOOL_NAMES from '../../tools/constants/toolNames';
import RepulserToolbarButton from './RepulserToolbarButton';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const toolbarModule = ({ commandsManager }) => {
  return {
    definitions: [
      {
        id: 'rt-edit',
        label: 'Edit',
        icon: 'inline-edit',
        buttons: [
          {
            id: 'rt-sphere-threshold',
            label: 'Lasso',
            icon: 'auto-circle',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: {
              toolName: TOOL_NAMES.SPHERE_THRESHOLD_TOOL,
            },
          },
          {
            id: 'rt-region-growing',
            label: 'Smart Brush',
            icon: 'auto-fix-high',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: {
              toolName: TOOL_NAMES.REGION_GROWING_TOOL,
            },
          },
          {
            id: 'rt-edge',
            label: 'Sketch',
            icon: 'inline-edit',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: TOOL_NAMES.EDGE_TOOL },
          },
          {
            id: 'rt-polygon',
            label: 'Polygon',
            icon: 'square-o',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: TOOL_NAMES.POLYGON_TOOL },
          },
          {
            id: 'rt-delete',
            label: 'Delete',
            icon: 'trash',
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: TOOL_NAMES.DELETE_TOOL },
          },
          {
            id: 'rt-delete-contours',
            label: 'Delete Contours',
            icon: 'delete-sweep',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'clearActiveViewportContours',
            commandOptions: {},
          },
          {
            id: 'rt-undo',
            label: 'Undo',
            icon: 'step-backward',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'undoRT',
            commandOptions: {},
          },
          {
            id: 'rt-redo',
            label: 'Redo',
            icon: 'step-forward',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'redoRT',
            commandOptions: {},
          },
          {
            id: 'rt-interpolate',
            label: 'Interpolate',
            icon: 'edit',
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'interpolateContour',
            commandOptions: {},
          },
        ],
      },
      {
        id: 'rt-repulser',
        label: 'Repulsor',
        icon: 'circle-o',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: TOOL_NAMES.REPULSER_TOOL },
        commandsManager,
        CustomComponent: RepulserToolbarButton,
      },
    ],
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
};

export default toolbarModule;
